<template>
  <div class="trends-page">
    <v-container class="pa-0 ma-0 text-start">
      <v-img
        :src="require(`@/assets/img/arrowleft.svg`)"
        contain
        @click="
          () => {
            $router.push({ name: 'trends' });
          }
        "
        class="mr-2"
        max-width="23"
      />
      <span
        @click="
          () => {
            $router.push({ name: 'trends' });
          }
        "
        class="back-text"
        >Volver a Tendencias</span
      >
    </v-container>
    <v-container fluid class="ma-0 pa-0">
      <iframe class="iframe" :src="article.media" frameborder="0" />
    </v-container>
    <v-row>
      <v-col />
      <v-col cols="7">
        <v-chip
          class="ma-2 roboto"
          color="tway-apple-green"
          text-color="white"
          v-for="article in article.tags"
          :key="article"
        >
          {{ article }}
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.text-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div,
  span {
    cursor: pointer;
  }
}
.text-center {
  display: flex;
  justify-content: center;
  align-content: center;
  div,
  span {
    cursor: pointer;
  }
}
.back-text {
  font-size: 16px;
  color: #999999;
}
.scrolltop {
  border-radius: 100%;
  background: #dcc5f4;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  div {
    max-width: 40% !important;
  }
}
.iframe {
  width: 100%;
  height: 80vh;
}
</style>

<script>
import CompanyService from "@/services/companyService";
import TrendsNewsJSON from "@/utils/trends-news.json";
import TrendService from "@/services/trendService";

export default {
  name: "TrendPost",

  data: () => ({
    breadcrumbs: [
      {
        text: "Tendencias",
        disabled: false,
        exact: true,
        to: {
          name: "trends",
        },
      },
      {
        text: "",
        disabled: true,
        exact: true,
        to: {
          name: "trends-post",
        },
      },
    ],
    posts: TrendsNewsJSON,
    article: {
      attribution: [],
      body: "",
      category: "",
      contentType: "",
      createdOn: "",
      flag: "",
      id: "",
      isPublic: null,
      media: "",
      status: null,
      summary: null,
      tags: [],
      title: "",
    },
    clientType: "",
  }),

  created() {
    this.getCompany();
    this.article.id = this.$route.params.id;
    //this.getArticle();
    window.scrollTo(0, 0);
    TrendService.getTrend(this.article.id)
      .then((trend) => {
        this.article = trend;
        this.breadcrumbs[1].text = this.article.title;
      })
      .catch((err) => {
        this.$log.error(err);
      });
  },

  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
    getCompany() {
      CompanyService.getCompanyProfile()
        .then((company) => (this.clientType = this._.get(company, "type")))
        .catch((err) => console.log(err));
    },
    getDate(timestamp) {
      let unix_timestamp = timestamp;
      var date = new Date(unix_timestamp);

      return date.toLocaleDateString("es", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      });
    },
  },

  watch: {},
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style scoped>
.article {
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
